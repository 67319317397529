@import './_theme';
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
html{
    font-size: 62.5%;
}

body, h1, h2, h3,h4,h5, p{
    margin: 0;
    padding: 0;
}

.p-password{
    width: 100%;
}

.login-inputs{
    height: 5.6rem;
    font-size: 1.6rem;
    .p-inputtext{
        font-size: 1.6rem;
    }
}

.pi {
    font-size: 2rem !important;
}

.p-toast{
    width: 40rem;
}

small {
    font-size: 1.2rem;
    color: var(--red-500);
    margin-top: 0.6rem;
    text-indent: 10px;
}

.dev-grid{
    display: grid;
    place-items: center;
    background-color: #ff5e00;
    p{
        padding: 1.2rem 0 !important;
        color: white !important;
        font-size: 1.2em !important;
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
